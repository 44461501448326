import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Button,
  Collapse,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  // UncontrolledTooltip,
  NavLink,
} from 'reactstrap';

import { signout, changeLanguage } from 'redux/actions/pages';

const CustomNavbar = ({
  collapseOpen,
  setCollapseOpen,
  navbarColor,
  logoRef,
  buttonColor,
  calledBy,
  auth,
  language,
  localizations,
  signout,
  changeLanguage,
  white,
}) => {
  useEffect(() => {
    if (white) {
      logoRef.current.src = require('assets/img/logo-classreaders.png').default;
    } else {
      logoRef.current.src = require('assets/img/logo-classreaders.png').default;
    }
  }, [logoRef, white]);

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={'fixed-top' + navbarColor}
        color="white"
        expand="lg"
        // style={{
        //   filter: 'blur(4px)',
        // }}
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
              <img
                className="cr-logo"
                ref={logoRef}
                src={require('assets/img/logo-classreaders.png').default}
                alt="Class Readers Logo"
              />
            </NavbarBrand>
            <button
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              <NavItem>
                <NavLink href="/contact">
                  <p>{localizations?.NAV_CONTACT ?? 'İLETİŞİM'}</p>
                </NavLink>
              </NavItem>

              {calledBy === 'signin' ? null : auth.currentUser?.id > 0 ? (
                <NavItem>
                  <NavLink>
                    <div
                      className="custom-navlink"
                      onClick={(e) => {
                        e.preventDefault();
                        signout({ email: auth?.currentUser?.email });
                      }}
                    >
                      <p>{localizations?.NAV_SIGNOUT ?? 'ÇIKIŞ'}</p>
                    </div>
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink href="/signin">
                    <p>{localizations?.NAV_SIGNIN ?? 'GİRİŞ'}</p>
                  </NavLink>
                </NavItem>
              )}
              {auth.currentUser?.id > 0 ? (
                auth.currentUser.userType === 'OTHER' ? (
                  <NavItem>
                    <Button
                      className="nav-link btn-default"
                      color={buttonColor}
                      href="https://accounts.okumaplatformu.com"
                      // target="_blank"
                    >
                      <p>{localizations?.NAV_ACCOUNTS ?? 'ÖĞRETMEN'}</p>
                    </Button>
                  </NavItem>
                ) : null
              ) : (
                <NavItem>
                  <Button
                    className="nav-link btn-default"
                    color={buttonColor}
                    href="/buy-now"
                    // target="_blank"
                  >
                    <p>{localizations?.NAV_BUY_NOW ?? 'SATIN AL'}</p>
                  </Button>
                </NavItem>
              )}

              {auth.currentUser?.id > 0 ? null : (
                <NavItem>
                  <NavLink>
                    <div
                      className="change-language"
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        changeLanguage({
                          language: language === 'TR' ? 'EN' : 'TR',
                        });
                      }}
                    >
                      <p>
                        <span
                          style={{ fontWeight: language === 'TR' ? 900 : 300 }}
                        >
                          TR
                        </span>{' '}
                        |{' '}
                        <span
                          style={{ fontWeight: language === 'EN' ? 900 : 300 }}
                        >
                          EN
                        </span>
                      </p>
                    </div>
                  </NavLink>
                </NavItem>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  language: state.localization?.language,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  signout,
  changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomNavbar);
