import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container, Col, Row, Alert, Button } from 'reactstrap';
// import FooterBlack from 'components/Custom/FooterBlack';
import { connect } from 'react-redux';

import DropdownFixedBlurNavbar from 'components/Custom/DropdownFixedBlurNavbar';
import RoundedCard from './home-components/RoundedCard';
import FooterBlack from 'components/Custom/FooterBlack';
import playstoreImage from 'assets/img/playstore-badge.png';
import appstoreImage from 'assets/img/appstore-badge.png';

const Home = ({ localizations, currentUser }) => {
  const history = useHistory();

  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const key = 'PrivacyAccepted';
    const item = localStorage.getItem(key);

    if (item && item !== '') {
      const expiry = parseInt(item);
      const now = new Date();

      if (now.getTime() > expiry) {
        localStorage.removeItem(key);
        if (!showConsent) {
          setShowConsent(true);
        }
      } else {
        setShowConsent(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser?.id > 0 && currentUser?.userType === 'STUDENT') {
      history.push('/student');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const consentAcceptClicked = (e) => {
    e.preventDefault();
    const now = new Date();
    const ttl = 1000 * 60 * 60 * 24;
    const expiry = now.getTime() + ttl;
    localStorage.setItem('PrivacyAccepted', expiry.toString());
    setShowConsent(false);
  };
  return (
    <>
      <div
        className="consent"
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 2010,
          width: '100%',
        }}
      >
        <Alert isOpen={showConsent} style={{ backgroundColor: '#F4F3ED' }}>
          <div
            className="consent-container"
            style={{
              padding: '10px 10% 10px 10%',
            }}
          >
            <div
              className="consent-content"
              style={{ fontSize: '16px', color: '#555555' }}
            >
              <div
                className="consent-title"
                style={{
                  fontWeight: 600,
                  marginBottom: '10px',
                  letterSpacing: '0.5px',
                }}
              >
                {localizations?.HOME_CONSENT_TITLE}
              </div>
              <div className="consent-text" style={{ marginBottom: '10px' }}>
                {localizations?.HOME_CONSENT_TEXT}
              </div>
              <div
                className="consent-footer"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  className="consent-link"
                  style={{ display: 'block', marginRight: '20px' }}
                >
                  {localizations?.HOME_CONSENT_FOOTER_PART1 + ' '}
                  <a href="#d">{localizations?.HOME_CONSENT_FOOTER_PART2}</a>
                  {' ' + localizations?.HOME_CONSENT_FOOTER_PART3}
                </div>
                <div className="consent-accept">
                  <Button
                    outline
                    className="btn-round"
                    type="button"
                    onClick={consentAcceptClicked}
                    style={{
                      backgroundColor: 'white',
                      color: '#555555',
                      borderColor: '#555555',
                    }}
                  >
                    {localizations?.HOME_CONSENT_ACCEPT_BTN}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Alert>
      </div>
      {/* <DefaultNavbar white={true} /> */}
      <DropdownFixedBlurNavbar />
      <div className="wrapper" style={{ backgroundColor: '#F5F5F7' }}>
        <div className="section-intro">
          <div className="badges">
            <p className="badges-text">
              Reading Platform
              <br />
              Mobile App is Launched
            </p>
            <div className="badges-images">
              <a
                href="https://apps.apple.com/tr/app/okuma-platformu-okul/id6444416015"
                target="_blank"
                rel="noreferrer"
              >
                <img src={appstoreImage} alt="Appstore Imaj" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.edictdigital.okumaplatformu"
                target="_blank"
                rel="noreferrer"
              >
                <img src={playstoreImage} alt="Playstore Imaj" />
              </a>
            </div>
          </div>
          <div className="intro-image-container">
            <div className="intro-pc">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/pc2.png"
                alt="pc görünümü"
              />
            </div>
            <div className="intro-iphone">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/iphone.png"
                alt="iphone görünümü"
              />
            </div>
            <div className="intro-ipad">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad.png"
                alt="ipad görünümü"
              />
            </div>
          </div>
        </div>

        <div className="section-intro-text">
          <Container>
            <Row>
              <Col sm={12} lg={{ span: 10 }}>
                <Row>
                  <Col>
                    <h1 className="intro-text-small-header">Class Readers</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="intro-text-title">
                      All Devices
                      <br />
                      7/24 Accesibility
                      <br />
                      Online
                      <br />
                      Reading Platform
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} />
                  <Col sm={12} lg={6}>
                    <div>
                      <p className="intro-text-description">
                        Are you ready for the adventures of Kerem, Bezgin, and
                        Çengel! The most entertaining form of reading with
                        digital, audiobooks consisting of 200+ stories.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#027FFF',
                    }}
                  >
                    Reading Tree
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <RoundedCard
                  title="200+ Books"
                  titleColor="#027FFF"
                  subtitle="The Reading Tree is divided into 13 levels for preschool and primary school students.
It is a reading series containing 200+ stories."
                  subtitleColor="rgb(29, 29, 31)"
                  backgroundColor="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      paddingTop: '70px',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'start',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/oka.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={6}>
                <RoundedCard
                  title="Reading Tree"
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, #1ABCFA, #1C7DF3)"
                >
                  <div className="home-rounded-card-text">
                    <p>
                      Powerful visual expression from real life, designed for
                      different reading levels.
                    </p>
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: 'rgb(205,58,13)',
                    }}
                  >
                    Student
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <RoundedCard
                  title="Fun Design For Kids"
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, rgb(247, 111,8), rgb(158, 9, 15))"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div className="home-rounded-card-image">
                    <img
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad_pro_11_home.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="User Friendly UI"
                  titleColor="rgb(205,58,13)"
                  subtitle="A unique reading experience with listening, recording audio, annotating, dictionary and zooming."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '80%',
                        maxWidth: '80%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad_read.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Glossary"
                  titleColor="rgb(205,58,13)"
                  subtitle=""
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sozluk.png"
                      alt="imaj"
                    />
                    <ul
                      style={{
                        listStyle: 'none',
                        fontSize: '19px',
                        lineHeight: 1.421,
                        fontWeight: 500,
                        letterSpacing: '0.012em',
                        margin: '0.3em 0 0.3em 0',
                        color: 'rgb(29, 29, 31)',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    >
                      <li style={{ marginBottom: '10px' }}>
                        Vocabulary from the page
                      </li>
                      <li style={{ marginBottom: '10px' }}>
                        Proverbs and idioms
                      </li>
                      <li style={{ marginBottom: '10px' }}>
                        More than one meaning
                      </li>
                      <li style={{ marginBottom: '10px' }}>Sample sentences</li>
                      <li style={{ marginBottom: '10px' }}>
                        Picture or video narration
                      </li>
                    </ul>
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="Audio Books"
                  titleColor="white"
                  subtitle="Word and sentence tracking while listening"
                  subtitleColor="white"
                  backgroundImage="https://ik.imagekit.io/mvnw0kw1mga/web/listen.png"
                  backgroundSize="cover"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  ></div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Auido Recording"
                  titleColor="rgb(205,58,13)"
                  subtitle={`Record your voice while reading.\nSend it to your teacher, share it with your loved ones, download it to your device.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '80%',
                        maxWidth: '80%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/rec.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Assessment"
                  titleColor="white"
                  subtitle="Check your reading comprehension, vocabulary and genre analysis development with assessments."
                  subtitleColor="white"
                  backgroundImage="https://ik.imagekit.io/mvnw0kw1mga/web/quiz.png"
                  backgroundSize="cover"
                  backgroundPosition="50% 0%"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  ></div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="Task"
                  titleColor="rgb(205,58,13)"
                  subtitle="Complete the teacher-given reading, listening, recording, assessment, and material tasks before the deadline."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/gorev.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Message"
                  titleColor="rgb(205,58,13)"
                  subtitle="View text or voice messages from the teacher."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '85%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/message.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="Reports"
                  titleColor="#FFFFFF"
                  subtitle="Track your progress."
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left,#3DD9BD,#049CB7)"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/rapor.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ marginBottom: '50px' }}>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#8876FE',
                    }}
                  >
                    Teacher
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="User Friendly Design"
                  titleColor="#FFFFFF"
                  subtitle="With user-based authorization, each teacher can display as many menus as their authorization."
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ogretmen_header.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Learning"
                  titleColor="#8876FE"
                  subtitle={`Books associated with Ministry of Education learning objectives.\nLearning materials for teachers and students.\nLesson plans for books.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ogrenim.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Materials"
                  titleColor="#8876FE"
                  subtitle={`Hundreds of learning materials.\nUse in class or assign a task as homework.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/document.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Tasks"
                  titleColor="#8876FE"
                  subtitle={`Creating tasks that include reading, listening, recording, assessment, and PDF material.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/newassignment.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Task Tracking"
                  titleColor="#8876FE"
                  subtitle={`Track the completion status of tasks. Listen to the reading recordings from the student.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/gorev_durumu.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="Class Report"
                  titleColor="white"
                  subtitle="Follow the overall status and progress of the class."
                  subtitleColor="white"
                  background="linear-gradient(to bottom right,#0D1387,#867AF6)"
                  // background="linear-gradient(to bottom right, rgb(228, 196, 159), rgb(252, 235, 214))"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sinif_raporu.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Class List"
                  titleColor="white"
                  subtitle="Follow the general status and progress of the class with lists."
                  subtitleColor="white"
                  background="linear-gradient(to top left, rgb(247, 111,8), rgb(158, 9, 15))"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sinif_raporu_listesi.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth?.currentUser,
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(Home);
