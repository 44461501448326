import React, { useRef, useState } from 'react';

import CustomNavbar from './CustomNavbar';

const DefaultNavbar = ({ calledBy, white }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [navbarColor, setNavbarColor] = useState(
    (document.documentElement.scrollTop >
      (window.innerWidth > 768 ? 499 : 199) || document.body.scrollTop) >
      (window.innerWidth > 768 ? 499 : 199)
      ? ''
      : ' navbar-transparent'
  );
  const [buyButtonColor, setBuyButtonColor] = useState(
    (document.documentElement.scrollTop >
      (window.innerWidth > 768 ? 499 : 199) || document.body.scrollTop) >
      (window.innerWidth > 768 ? 499 : 199)
      ? 'secondary'
      : 'neutral'
  );

  // const [navIconColor, setNavIconColor] = useState('#FFFFFF');

  const logoRef = useRef(null);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      const heightBreakPoint = window.innerWidth > 768 ? 499 : 199;
      if (
        document.documentElement.scrollTop > heightBreakPoint ||
        document.body.scrollTop > heightBreakPoint
      ) {
        setNavbarColor('');
        setBuyButtonColor('secondary');
        logoRef.current.src =
          require('assets/img/logo-classreaders.png').default;
        // setNavIconColor('#888888');
      } else if (
        document.documentElement.scrollTop < heightBreakPoint + 1 ||
        document.body.scrollTop < heightBreakPoint + 1
      ) {
        setNavbarColor(' navbar-transparent');
        setBuyButtonColor('neutral');
        logoRef.current.src =
          require('assets/img/logo-classreaders.png').default;
        // setNavIconColor('#FFFFFF');
      }
    };
    window.addEventListener('scroll', updateNavbarColor);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <CustomNavbar
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
      navbarColor={navbarColor}
      logoRef={logoRef}
      buttonColor={buyButtonColor}
      calledBy={calledBy}
      white={white}
    />
  );
};

export default DefaultNavbar;
